"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { isFormula } from '@integration-app/sdk';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { TagPlaceholder } from '../../Tag/TagPlaceholder.mjs';
import { useComboBox } from '../context/combobox-context.mjs';
import { ComboBoxFormulaValue } from './Formula.mjs';
import { ComboBoxSchemaValue } from './Schema.mjs';
import { isDefinedValue } from './isDefinedValue.mjs';

const ComboBoxValue = forwardRef(function({ placeholder, isEdited, disabled, onClick, onChange, className }, ref) {
  const { value, variablesSchema, schema, options, optionFactories } = useComboBox();
  const isValueDefined = isDefinedValue(value);
  if (isEdited && (!isValueDefined || value === "")) {
    return /* @__PURE__ */ jsx(TagPlaceholder, { ref, className });
  }
  if (isValueDefined) {
    if (isFormula(value)) {
      return /* @__PURE__ */ jsx(
        ComboBoxFormulaValue,
        {
          ref,
          value,
          schema,
          variablesSchema,
          optionFactories,
          disabled,
          onClick,
          onChange,
          className
        }
      );
    }
    if (options) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        return /* @__PURE__ */ jsx(ComboBoxTag, { ref, disabled, className, children: selectedOption.label });
      }
    }
    return /* @__PURE__ */ jsx(
      ComboBoxSchemaValue,
      {
        ref,
        value,
        schema,
        variablesSchema,
        disabled,
        onClick,
        className
      }
    );
  } else {
    return /* @__PURE__ */ jsx(TagPlaceholder, { ref, onClick, className, children: placeholder });
  }
});
ComboBoxValue.displayName = "ComboBoxValue";

export { ComboBoxValue };
