"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { ark } from '@ark-ui/react/factory';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { alert } from '../../styled-system/recipes/alert.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';

const { withProvider, withContext } = createStyleContext(alert);
const Root = withProvider(ark.div, "root");
withContext(ark.div, "content");
withContext(ark.div, "description");
const Icon = withContext(ark.svg, "icon");
const Title = withContext(ark.h5, "title");

export { Icon, Root, Title };
