"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { useLayoutEffect } from 'react';

function useAutofocus(elementRef, autoFocus) {
  useLayoutEffect(() => {
    if (autoFocus) {
      elementRef.current.focus({ preventScroll: true });
    }
  }, [autoFocus]);
}

export { useAutofocus };
