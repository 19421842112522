import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const popupDefaultVariants = {}
const popupCompoundVariants = []

const popupSlotNames = [
  [
    "trigger",
    "popup__trigger"
  ],
  [
    "backdrop",
    "popup__backdrop"
  ],
  [
    "positioner",
    "popup__positioner"
  ],
  [
    "window",
    "popup__window"
  ],
  [
    "header",
    "popup__header"
  ],
  [
    "content",
    "popup__content"
  ],
  [
    "footer",
    "popup__footer"
  ],
  [
    "title",
    "popup__title"
  ],
  [
    "description",
    "popup__description"
  ],
  [
    "closeTrigger",
    "popup__closeTrigger"
  ]
]
const popupSlotFns = /* @__PURE__ */ popupSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, popupDefaultVariants, getSlotCompoundVariant(popupCompoundVariants, slotName))])

const popupFn = memo((props = {}) => {
  return Object.fromEntries(popupSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const popupVariantKeys = []
const getVariantProps = (variants) => ({ ...popupDefaultVariants, ...compact(variants) })

export const popup = /* @__PURE__ */ Object.assign(popupFn, {
  __recipe__: false,
  __name__: 'popup',
  raw: (props) => props,
  variantKeys: popupVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, popupVariantKeys)
  },
  getVariantProps
})