"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { getFormula } from '@integration-app/sdk';
import { getFormulas } from '../../DataBuilder/Formula/index.mjs';
import NoUiFormula from '../../DataBuilder/Formula/noUi.mjs';

const ComboBoxFormulaValue = forwardRef(
  ({ value, ...props }, ref) => {
    const formula = getFormula(value);
    if (!formula) {
      return /* @__PURE__ */ jsx(NoUiFormula, {});
    }
    const formulaSpec = getFormulas()[formula.type];
    if (!formulaSpec?.component) {
      return /* @__PURE__ */ jsx(NoUiFormula, {});
    }
    const componentProps = {
      formula,
      ...props
    };
    const Component = formulaSpec.component;
    return /* @__PURE__ */ jsx(Component, { ...componentProps, ref });
  }
);
ComboBoxFormulaValue.displayName = "ComboBoxFormulaValue";

export { ComboBoxFormulaValue };
