"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { Portal as Portal$1 } from 'react-portal';
import { getRefElement } from '../../helpers/getRefElement.mjs';

const isStorybook = Boolean(globalThis?.__STORYBOOK_PREVIEW__);
const storybookPortalNode = "#storybook-root";
function Portal({
  node,
  children
}) {
  return /* @__PURE__ */ jsx(Portal$1, { node: getPortalNode(node), children });
}
function getPortalNode(portalNode) {
  if (!portalNode) {
    if (isStorybook) {
      return getPortalNode(storybookPortalNode);
    }
    return null;
  }
  if (typeof portalNode === "string") {
    return document?.querySelector?.(cssEscapedSelector(portalNode));
  }
  if (typeof portalNode === "function") {
    return portalNode();
  }
  return getRefElement(portalNode);
}
function cssEscapedSelector(selector) {
  if (selector.startsWith("#") || selector.startsWith(".")) {
    const firstChar = selector[0];
    const rest = selector.slice(1);
    return `${firstChar}${CSS.escape(rest)}`;
  }
  return selector;
}

export { Portal };
