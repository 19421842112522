"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import classes from './Input.module.css.mjs';
import { useAutofocus } from '../../hooks/useAutofocus.mjs';

const Input = forwardRef(
  ({ variant, className, autoFocus, ...props }, ref) => {
    const inputRef = useRef();
    useImperativeHandle(ref, () => inputRef.current);
    useAutofocus(inputRef, autoFocus);
    return /* @__PURE__ */ jsx(
      "input",
      {
        ref: inputRef,
        autoComplete: "off",
        className: clsx(
          classes["uikit-input"],
          getClassNameByVariant(variant),
          className
        ),
        ...props
      }
    );
  }
);
Input.displayName = "Input";
function getClassNameByVariant(variant) {
  switch (variant) {
    case "background":
      return classes["uikit-input__background"];
    case "transparent":
      return classes["uikit-input__transparent"];
    case "border":
    default:
      return classes["uikit-input__border"];
  }
}

export { Input };
