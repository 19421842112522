"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef, useId, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useIntersectionObserver } from 'usehooks-ts';
import { dropdownRecipe } from './sva-recipe.mjs';
import { DropdownItemContextProvider } from './item-context.mjs';
import { useForwardedRef } from '../../hooks/useForwardedRef.mjs';
import { preventFocusShift } from './utils.mjs';

const Item = forwardRef(({ children, className, current = false, ...props }, ref) => {
  const id = useId();
  const styles = dropdownRecipe();
  return /* @__PURE__ */ jsx(DropdownItemContextProvider, { value: { id }, children: /* @__PURE__ */ jsx(
    DropdownItemDiv,
    {
      ref,
      active: current,
      className: clsx(styles.item, className),
      "data-current": current || void 0,
      role: "listitem",
      "aria-labelledby": id,
      onMouseDown: preventFocusShift,
      ...props,
      children
    }
  ) });
});
const DropdownItemDiv = forwardRef(
  ({ children, active, ...props }, ref) => {
    if (active) {
      return /* @__PURE__ */ jsx(ScrollIntoViewDiv, { ...props, children });
    }
    return /* @__PURE__ */ jsx("div", { ref, ...props, children });
  }
);
const ScrollIntoViewDiv = forwardRef(({ children, ...props }, ref) => {
  const actualRef = useForwardedRef(ref);
  useScrollIntoViewOnce(actualRef);
  return /* @__PURE__ */ jsx("div", { ...props, ref: actualRef, children });
});
function useScrollIntoViewOnce(ref) {
  const threshold = 0.7;
  const freezeOnceVisible = true;
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible
  });
  useLayoutEffect(() => {
    if (!entry) {
      return;
    }
    if (
      // Hack for TS
      // IntersectionObserverEntry.isVisible is not in the type definition
      entry["isVisible"] || entry.isIntersecting && entry.intersectionRatio > 0.7
    ) {
      return;
    }
    entry.target.scrollIntoView({
      block: "nearest"
    });
  }, [entry]);
}

export { Item };
