"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { useComboBox } from './combobox-context.mjs';

const ComboBoxDropdownSearchContext = createContext(
  {
    hideSearch: false,
    searchValue: "",
    setSearchValue: () => null,
    searchSource: "editor",
    setSearchSource: () => {
    }
  }
);
ComboBoxDropdownSearchContext.displayName = "ComboBoxDropdownSearchContext";
const ComboBoxDropdownSearchProvider = ({
  children,
  hideSearch = false
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchSource, setSearchSource] = useState("editor");
  const { isDropdownOpen } = useComboBox();
  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      hideSearch,
      searchSource,
      setSearchSource
    }),
    [searchValue, hideSearch, searchSource]
  );
  useEffect(() => {
    if (!isDropdownOpen && searchValue) {
      setSearchValue("");
    }
  }, [isDropdownOpen]);
  return /* @__PURE__ */ jsx(ComboBoxDropdownSearchContext.Provider, { value: contextValue, children });
};
function useComboBoxDropdownSearchContext() {
  return useContext(ComboBoxDropdownSearchContext);
}

export { ComboBoxDropdownSearchProvider, useComboBoxDropdownSearchContext };
