"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import deepEqual from 'fast-deep-equal';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useDeepEffect } from '../../../hooks/useDeepEffect.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../hooks/keyboard-events.mjs';
import { useNavigationContext } from './NavigationContext.mjs';
import DropdownOption from './Option.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import useDataBuilderField from '../../DataBuilder/field-context.mjs';
import { toSnakeCase } from 'js-convert-case';
import { useComboBox } from '../context/combobox-context.mjs';

function OptionsList({
  onOptionSelect
}) {
  const { onAddVariable } = useDataBuilder();
  const { field } = useDataBuilderField();
  const {
    isOptionSelected,
    canUseParentInputSearchValue,
    drillDown,
    optionsPayload,
    drillUp
  } = useComboBoxDropdownContext();
  const { value: parentInputValue, scopedComboboxId } = useComboBox();
  const {
    currentItem,
    moveToNextItem,
    moveToPreviousItem,
    onOptionsListChange
  } = useNavigationContext();
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_DOWN,
    callback: () => {
      moveToPreviousItem(options, currentItem);
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_UP,
    callback: () => {
      moveToNextItem(options, currentItem);
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_LEFT,
    callback: () => {
      drillUp();
    }
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_RIGHT,
    callback: handleKeyEnter
  });
  useKeyboardEventSubscribe({
    scopedComboboxId,
    event: EVENT_TYPE.OPTION_SELECT,
    callback: handleKeyEnter
  });
  const [options = [], optionsError, optionsState] = optionsPayload;
  useDeepEffect(() => {
    onOptionsListChange(options);
  }, [options]);
  function handleKeyEnter() {
    if (currentItem) {
      handleOptionSelect(currentItem);
    }
  }
  function handleOptionSelect(option) {
    if (option === "drill-up") {
      drillUp();
      return;
    }
    if (option.addVariable) {
      const objectLocator = option.addVariable;
      const variableLocator = `${objectLocator}.${field.key || toSnakeCase(field.name) || toSnakeCase(field?.schema?.title)}`;
      onAddVariable?.(variableLocator, field.schema ?? {});
      onOptionSelect({
        $var: variableLocator
      });
    } else if (option.drillDownOptionFactory || option.childCategory && !option.value) {
      drillDown(option);
    } else {
      onOptionSelect(option.value);
    }
  }
  return /* @__PURE__ */ jsx(DropdownList, { state: optionsState, error: optionsError, children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      searchTerm: canUseParentInputSearchValue ? parentInputValue : void 0,
      options,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownList({
  state,
  error,
  children
}) {
  if (state === "rejected" || error) {
    return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsx(
      DropdownUI.ErrorHeading,
      {
        text: error?.message || "Something went wrong"
      }
    ) });
  }
  if (state === "pending") {
    return /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
      /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {})
    ] });
  }
  return /* @__PURE__ */ jsx(DropdownUI.List, { children });
}
function DropdownListOptions({
  options,
  isOptionSelected,
  handleOptionSelect,
  searchTerm
}) {
  const { currentItem } = useNavigationContext();
  if (!options) {
    return null;
  }
  if (options.length === 0) {
    return /* @__PURE__ */ jsx(
      DropdownUI.NoOptionsHeading,
      {
        text: searchTerm ? `No results found for: ${searchTerm}` : "No results found"
      }
    );
  }
  return /* @__PURE__ */ jsx(Fragment, { children: options.map((option, idx) => /* @__PURE__ */ jsx(
    DropdownOption,
    {
      option,
      selected: isOptionSelected(option),
      current: deepEqual(option, currentItem),
      onSelect: () => handleOptionSelect(option)
    },
    idx
  )) });
}

export { OptionsList };
