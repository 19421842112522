"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { dropdownRecipe } from './sva-recipe.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';

const Content = forwardRef(({ children, className, ...props }, ref) => {
  const { content } = dropdownRecipe({});
  return /* @__PURE__ */ jsx(Flex, { ref, className: clsx(content, className), ...props, children });
});

export { Content };
