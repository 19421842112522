"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { StandAloneEditor } from '../../ComboBoxElements/TipTapEditor/EditorStandAlone.mjs';

const TplFormula = forwardRef(
  (props, ref) => {
    const {
      formula,
      schema: _,
      variablesSchema: __,
      optionFactories: ___,
      ...editorProps
    } = props;
    const value = {
      $tpl: {
        template: formula.args.template,
        values: formula.args.values
      }
    };
    return /* @__PURE__ */ jsx(StandAloneEditor, { ref, value, ...editorProps });
  }
);
TplFormula.displayName = "TplFormula";

export { TplFormula as default };
