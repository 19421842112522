"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { toHeaderCase } from 'js-convert-case';
import { isValidLocator, locatorToSteps, getSchemaByLocator, locatorToField, stepsToLocator, DataLocatorStepObjectProperty, getNameComponentsForLocator, getFullNameForLocator, getNameForLocator, getIconUriForLocator } from '@integration-app/sdk';
import { filterOptionsByLabel } from './utils.mjs';
import { getOptionsForSchema } from './generate-schema-options.mjs';
import { doesSchemaMatchValueType } from '../value-spec.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';

class ObjectFieldsOptionFactory {
  constructor(parameters) {
    this.parameters = parameters;
  }
  async getOptions({
    input,
    spec
  }) {
    return this.getOptionsSync({ input, spec });
  }
  getOptionsSync({ input, spec }) {
    const locator = this.parameters.locator ?? "$";
    if (!isValidLocator(locator)) {
      return [];
    }
    const locatorSteps = locatorToSteps(locator);
    const objectSchema = getSchemaByLocator(this.parameters.schema, locator);
    if (objectSchema?.type !== "object") {
      return [];
    }
    const isSchemaEditable = this.parameters.editableSchemaLocators?.some(
      (l) => locatorToField(stepsToLocator(locatorSteps)).startsWith(
        locatorToField(l)
      )
    );
    const showFullName = !!input;
    const options = [];
    if (doesSchemaMatchValueType(objectSchema, spec) && locator && locator !== "$") {
      options.push({
        label: "Whole Object",
        value: {
          $var: stepsToLocator(locatorToSteps(locator))
        },
        iconType: SvgIconType.SchemaType_object
      });
    }
    for (const [fieldKey, fieldSchema] of Object.entries(
      objectSchema.properties || {}
    )) {
      const propertyLocator = [
        ...locatorSteps,
        new DataLocatorStepObjectProperty(fieldKey)
      ];
      const objectNameComponents = getNameComponentsForLocator(
        this.parameters.schema,
        locator
      );
      options.push(
        ...getOptionsForSchema({
          schema: this.parameters.schema,
          locator: propertyLocator,
          valueSpec: spec,
          showIcons: !this.parameters.isSection,
          schemaByLocator: fieldSchema,
          exampleVariables: this.parameters.exampleVariables,
          skipNameComponentsPrefix: objectNameComponents,
          nameComponentsByLocator: [
            /* FIXME: strictNullCheck temporary fix */
            // @ts-expect-error TS(2488): Type 'string[] | undefined' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
            ...objectNameComponents,
            fieldSchema?.title ?? toHeaderCase(fieldKey)
          ],
          expandObject: false
        })
      );
    }
    if (isSchemaEditable) {
      options.push({
        label: `New${this.parameters?.name ? ` "${this.parameters.name}"` : ""} property`,
        searchLabel: "",
        iconType: SvgIconType.Add,
        addVariable: `${stepsToLocator(locatorSteps)}`
      });
    }
    const filteredOptions = filterOptionsByLabel(options, input);
    if (filteredOptions.length > 0) {
      const result = filteredOptions;
      if (this.parameters.isSection) {
        result.unshift({
          label: showFullName ? getFullNameForLocator(this.parameters.schema, locator) : getNameForLocator(this.parameters.schema, locator),
          isSection: true,
          iconUri: getIconUriForLocator(this.parameters.schema, locator)
        });
      }
      return result;
    } else {
      return [];
    }
  }
}

export { ObjectFieldsOptionFactory };
