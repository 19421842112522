"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import usePromise from '../../../_modules/react-use-promise.mjs';
import deepEqual from 'fast-deep-equal';
import { useIntegrationAppClient } from '../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../contexts/integration-app-integration-context.mjs';
import { BooleanOptionsFactory } from '../options-factories/boolean.mjs';
import { ConstantOptionFactory } from '../options-factories/constant.mjs';
import { EnumOptionFactory } from '../options-factories/enum.mjs';
import { ReferenceRecordsOptionsFactory } from '../options-factories/reference-records.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { getAvailableRootCategories, getCurrentPath, getStackForPath } from './helpers.mjs';
import { useComboBox } from './combobox-context.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import { RootOptionFactory } from '../options-factories/root.mjs';
import { useComboBoxDropdownSearchContext } from './combobox-dropdown-search.mjs';

const ComboBoxDropdownContext = createContext({
  availableCategories: [],
  activeCategory: DropdownCategoryType.ROOT,
  drillDown: () => {
  },
  drillUp: () => {
  },
  isOptionSelected: () => false,
  optionsPayload: [[], void 0, "resolved"],
  canUseParentInputSearchValue: false,
  drillDownStackTop: void 0
});
ComboBoxDropdownContext.displayName = "ComboBoxDropdownContext";
const ComboBoxDropdownContextProvider = ({
  children
}) => {
  const { editableVariablesSchemaLocators } = useDataBuilder();
  const {
    value,
    schema,
    valueSpec,
    variablesSchema,
    variablesOnly,
    options,
    optionFactories,
    isDropdownOpen
  } = useComboBox();
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const { setSearchValue, searchValue, searchSource } = useComboBoxDropdownSearchContext();
  const [drillDownOptionsStack, setDrillDownOptionsStack] = useState([]);
  const [availableCategories, setAvailableCategories] = useState(null);
  const [drillPath, setDrillPath] = useState([]);
  const hasVariables = variablesSchema?.type === "object" && Object.keys(variablesSchema.properties ?? {}).length > 0;
  const recoverStack = (path, availableCategories2) => {
    void getStackForPath(
      path,
      value,
      (searchValue2, stack) => getOptions({
        availableCategories: availableCategories2,
        schema,
        variablesSchema,
        searchValue: searchValue2,
        valueSpec,
        drillDownStack: stack,
        staticOptions: options,
        optionFactories,
        editableVariablesSchemaLocators
      }),
      [],
      canUseParentInputSearchValue ? value : searchValue
    ).then((stack) => {
      setDrillDownOptionsStack(stack);
    });
  };
  useEffect(() => {
    void getAvailableRootCategories({
      schema,
      options,
      optionFactories,
      variablesOnly,
      hasVariables,
      client,
      connectionId,
      integrationId
    }).then((categories) => {
      setAvailableCategories(categories);
      if (!isDropdownOpen) {
        const currentPath = getCurrentPath(categories || [], value);
        setDrillPath(currentPath);
        recoverStack(currentPath, categories);
      }
    });
  }, [
    schema,
    options,
    optionFactories,
    variablesOnly,
    hasVariables,
    client,
    integrationId,
    connectionId
  ]);
  useEffect(() => {
    if (!isDropdownOpen) {
      resetDrillDown();
    } else {
      recoverStack(drillPath, availableCategories);
    }
  }, [isDropdownOpen]);
  const [activeCategory] = drillPath.toReversed();
  function drillDown(option) {
    setSearchValue("");
    const newStack = [...drillDownOptionsStack, option];
    setDrillDownOptionsStack(newStack);
    setDrillPath((path) => {
      if (option.childCategory) {
        return [...path, option.childCategory];
      }
      return path;
    });
  }
  function drillUp() {
    if (drillDownOptionsStack.length) {
      const newStack = [...drillDownOptionsStack];
      newStack.pop();
      setDrillDownOptionsStack(newStack);
      const newPath = [...drillPath];
      newPath.pop();
      if (!newPath.length) {
        setDrillPath(getCurrentPath(availableCategories || [], value));
      } else {
        setDrillPath(newPath);
      }
    }
  }
  function resetDrillDown() {
    setDrillDownOptionsStack([]);
    setDrillPath(getCurrentPath(availableCategories || [], value));
  }
  const canUseParentInputSearchValue = !searchValue && typeof value === "string" && !!value && drillDownOptionsStack.length === 0 && searchSource === "editor";
  const optionsPayload = usePromise(() => {
    return getOptions({
      availableCategories,
      schema,
      variablesSchema,
      searchValue: canUseParentInputSearchValue ? value : searchValue,
      valueSpec,
      drillDownStack: drillDownOptionsStack,
      staticOptions: options,
      optionFactories,
      editableVariablesSchemaLocators
    });
  }, [
    activeCategory,
    JSON.stringify(availableCategories),
    JSON.stringify(schema),
    JSON.stringify(variablesSchema),
    value,
    canUseParentInputSearchValue,
    searchValue,
    JSON.stringify(valueSpec),
    drillDownOptionsStack.length,
    JSON.stringify(options),
    optionFactories?.length,
    JSON.stringify(editableVariablesSchemaLocators)
  ]);
  const isOptionSelected = useCallback(
    function(option) {
      if (value === void 0 || value === null) {
        return false;
      }
      return deepEqual(option.value, value);
    },
    [value]
  );
  const [drillDownStackTop] = drillDownOptionsStack.toReversed();
  return /* @__PURE__ */ jsx(
    ComboBoxDropdownContext.Provider,
    {
      value: {
        availableCategories,
        activeCategory,
        drillDown,
        drillUp,
        isOptionSelected,
        optionsPayload,
        canUseParentInputSearchValue,
        drillDownStackTop
      },
      children
    }
  );
};
function useComboBoxDropdownContext() {
  return useContext(ComboBoxDropdownContext);
}
async function getOptions({
  availableCategories,
  schema,
  variablesSchema,
  searchValue,
  valueSpec,
  drillDownStack,
  staticOptions,
  optionFactories = [],
  editableVariablesSchemaLocators
}) {
  const factories = [...optionFactories];
  if (drillDownStack.length === 0) {
    factories.push(new RootOptionFactory(availableCategories));
    if (schema?.referenceRecords) {
      factories.push(new ReferenceRecordsOptionsFactory({ schema }));
    } else if (schema?.enum) {
      factories.push(new EnumOptionFactory({ schema }));
    } else if (schema?.type === "boolean") {
      factories.push(new BooleanOptionsFactory());
    } else if (staticOptions) {
      factories.push(new ConstantOptionFactory(staticOptions));
    }
  }
  const options = [];
  async function getOptionsFromFactory(factory) {
    const options2 = [];
    options2.push(
      ...await factory.getOptions({
        input: searchValue,
        spec: valueSpec,
        editableVariablesSchemaLocators,
        variablesSchema
      })
    );
    if (searchValue) {
      const unfilteredOptions = await factory.getOptions({
        input: "",
        spec: valueSpec,
        editableVariablesSchemaLocators,
        variablesSchema
      });
      for (const option of unfilteredOptions) {
        if (option.drillDownOptionFactory) {
          options2.push(
            ...await getOptionsFromFactory(option.drillDownOptionFactory)
          );
        }
      }
    }
    return options2;
  }
  if (drillDownStack.length > 0) {
    const option = drillDownStack[drillDownStack.length - 1];
    if (option?.drillDownOptionFactory) {
      options.push(
        ...await getOptionsFromFactory(option?.drillDownOptionFactory)
      );
    }
  } else {
    const localOptions = [];
    for (const optionFactory of [...factories]) {
      localOptions.push(...await getOptionsFromFactory(optionFactory));
    }
    options.push(...localOptions);
  }
  return options;
}

export { ComboBoxDropdownContextProvider, useComboBoxDropdownContext };
