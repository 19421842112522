"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { useEffect } from 'react';
import '../../../ui-kit/styled/popover.mjs';
import { usePopoverContext } from '@ark-ui/react/popover';

const useMountDropdownReposition = () => {
  const context = usePopoverContext();
  useEffect(() => {
    context.reposition();
  }, [context.reposition]);
};

export { useMountDropdownReposition };
