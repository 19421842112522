"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { NestedLevel } from '../../../DataBuilderUI/index.mjs';
import { isObjectAllowedAdditionalProperties, isFieldVisible, fieldLabel } from '../../data-field.mjs';
import useDataBuilderField from '../../field-context.mjs';
import { ControlPanelForNonObjectRootField } from './ControlPanelForNonObjectRootField.mjs';
import { ControlPanel } from './ControlPanel.mjs';
import { FieldControlPanel } from '../../../DataBuilderUI/FieldControlPanel/index.mjs';

function DataBuilderFieldControlPanel({
  field,
  onAdd,
  addFieldLabel,
  additionalFields = []
}) {
  const showAddFieldButton = isObjectAllowedAdditionalProperties(field) || additionalFields.length !== 0;
  const fieldIsVisible = isFieldVisible(field);
  let level = field.level;
  const computedAddFieldLabel = useAddFieldLabel(addFieldLabel);
  if (level < 0 || !showAddFieldButton) {
    return null;
  }
  if (!fieldIsVisible) {
    level--;
  }
  if (field?.schema?.type !== "object" && level <= 0) {
    return /* @__PURE__ */ jsx(FieldControlPanelWrapper, { field, children: /* @__PURE__ */ jsx(
      ControlPanelForNonObjectRootField,
      {
        addFieldLabel: computedAddFieldLabel,
        onAdd
      }
    ) });
  }
  return /* @__PURE__ */ jsx(FieldControlPanelWrapper, { field, children: /* @__PURE__ */ jsx(
    ControlPanel,
    {
      field,
      addFieldLabel: computedAddFieldLabel,
      onAdd,
      additionalFields
    }
  ) });
}
function FieldControlPanelWrapper({
  field,
  children
}) {
  const fieldIsVisible = isFieldVisible(field);
  const level = fieldIsVisible ? field.level + 1 : field.level;
  return /* @__PURE__ */ jsx(NestedLevel, { level, children: /* @__PURE__ */ jsx(FieldControlPanel, { compact: fieldIsVisible, children }) });
}
function useAddFieldLabel(labelFromProps) {
  const { field, localSchema } = useDataBuilderField();
  if (labelFromProps) {
    return labelFromProps;
  }
  const resultArray = ["Add"];
  const label = fieldLabel(field);
  if (label) {
    resultArray.push(label);
  }
  resultArray.push(localSchema?.type === "object" ? "Field" : "Item");
  return resultArray.join(" ");
}

export { ControlPanel, ControlPanelForNonObjectRootField, DataBuilderFieldControlPanel };
