import { TbX } from 'react-icons/tb'
import { Pane } from 'split-pane-react'
import useApi from 'hooks/useApi'
import { Tooltip } from 'components/Tooltip'
import { Icon } from 'ui-kit/icon'
import classes from './Impersonation.module.css'

export function ImpersonationPane() {
  const { getImpersonationToken, unsetImpersonationToken } = useApi()

  async function dropImpersonation() {
    unsetImpersonationToken()
    window.location.pathname = '/'
  }

  if (!getImpersonationToken()) {
    return null
  }

  return (
    <Pane className={classes.pane}>
      <Tooltip tooltip={'Cancel impersonation'}>
        <button className={classes.button} onClick={dropImpersonation}>
          <span className={'truncate'}>Impersonation</span>
          <Icon size={'xs'}>
            <TbX />
          </Icon>
        </button>
      </Tooltip>
    </Pane>
  )
}
