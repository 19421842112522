import { useEffect, useState } from 'react'
import { Portal } from '@ark-ui/react'
import useApi from 'hooks/useApi'
import classes from './Impersonation.module.css'

export function ImpersonationVisualNotification() {
  const [isClient, setIsClient] = useState(false)
  const { getImpersonationToken } = useApi()

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  if (!getImpersonationToken()) {
    return null
  }

  return (
    <Portal>
      <div className={classes.border}></div>
    </Portal>
  )
}
