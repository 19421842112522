"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import useEventStopPropagation from '../../../hooks/useEventStopPropagation.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';

function DropdownOption({
  option,
  selected,
  current = false,
  onSelect
}) {
  const handleOnSelect = useEventStopPropagation(() => {
    if (option.isSection && !option.drillDownOptionFactory) {
      return;
    }
    onSelect?.();
  });
  const iconProps = getIconProps(option);
  const valueProps = getValueProps(option);
  const hasChild = !!option.drillDownOptionFactory;
  if (option.isSection) {
    return /* @__PURE__ */ jsx(DropdownUI.ListHeader, { children: valueProps.label });
  }
  return /* @__PURE__ */ jsxs(
    DropdownUI.Item,
    {
      current,
      onClick: handleOnSelect,
      "aria-selected": selected,
      children: [
        /* @__PURE__ */ jsx(DropdownUI.ItemIcon, { ...iconProps, framed: true }),
        /* @__PURE__ */ jsx(DropdownUI.ItemTitle, { children: option.label }),
        hasChild && /* @__PURE__ */ jsx(DropdownUI.ItemNestingIndicator, { count: option.childrenCount, children: /* @__PURE__ */ jsx(DropdownUI.ItemIcon, { type: SvgIconType.ChevronRight }) }),
        selected && /* @__PURE__ */ jsx(DropdownUI.ItemIcon, { type: SvgIconType.Check })
      ]
    }
  );
}
function getIconProps(option) {
  const { iconType: type, iconUri: src } = option;
  return { type, src };
}
function getValueProps(option) {
  const { label } = option;
  return {
    label: label ?? "Missing label"
  };
}

export { DropdownOption as default };
