"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { schemaWithTitle } from '@integration-app/sdk';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { DrilldownBlockContentWrapper } from '../../DataBuilderUI/index.mjs';
import DataBuilderFormField from '../Field.mjs';
import useDataBuilderField from '../field-context.mjs';
import { DataBuilderFieldControlPanel } from '../Nested/FieldControlPanel/index.mjs';
import { makeSubField } from '../data-field.mjs';

const MergeObjectsFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Merge objects"
    ] }) });
  }
);
MergeObjectsFormula.displayName = "MergeObjectsFormula";
function MergeObjectsDropdown({
  onChange,
  formula
}) {
  const { field } = useDataBuilderField();
  const renderedField = {
    ...field,
    value: {
      $mergeObjects: formula.args
    }
  };
  return /* @__PURE__ */ jsx(MergeObjectsNested, { field: renderedField, onChange });
}
function MergeObjectsNested({ field, onChange }) {
  const values = field.value?.$mergeObjects ?? [];
  function handleChangeValue(index, value) {
    const newValues = [...values];
    newValues[index] = value;
    onChange?.({ $mergeObjects: newValues });
  }
  function handleDeleteValue(index) {
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange?.({ $mergeObjects: newValues });
  }
  function handleAddValue() {
    const newValues = [...values];
    newValues.push(null);
    onChange?.({ $mergeObjects: newValues });
  }
  return /* @__PURE__ */ jsxs(DrilldownBlockContentWrapper, { children: [
    values.map((value, index) => {
      const subField = makeSubField(field, {
        schema: schemaWithTitle(field?.schema, "") ?? {
          type: "object",
          additionalProperties: true
        },
        valueLocator: `${field.valueLocator}.$mergeObjects.${index}`,
        value,
        isDeletable: true
      });
      return /* @__PURE__ */ jsx(
        DataBuilderFormField,
        {
          field: subField,
          onDelete: () => handleDeleteValue(index),
          onChange: (value2) => handleChangeValue(index, value2)
        },
        index
      );
    }),
    /* @__PURE__ */ jsx(DataBuilderFieldControlPanel, { field, onAdd: handleAddValue })
  ] });
}

export { MergeObjectsDropdown, MergeObjectsFormula as default };
