"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useComboBoxDropdownSearchContext } from '../context/combobox-dropdown-search.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { renderCategories } from './categoryContentMapping.mjs';
import { useComboBox } from '../context/combobox-context.mjs';

function SearchInput({ autoFocus }) {
  const { activeCategory } = useComboBoxDropdownContext();
  const { hideSearch } = useComboBoxDropdownSearchContext();
  if (activeCategory && renderCategories[activeCategory]?.hideSearchInput) {
    return null;
  }
  if (hideSearch) {
    return null;
  }
  return /* @__PURE__ */ jsx(DropdownSearchComponent, { autoFocus });
}
function DropdownSearchComponent({
  autoFocus
}) {
  const { value: parentInputValue } = useComboBox();
  const { canUseParentInputSearchValue } = useComboBoxDropdownContext();
  const { searchValue, setSearchValue, setSearchSource } = useComboBoxDropdownSearchContext();
  return /* @__PURE__ */ jsx(
    DropdownUI.Search,
    {
      value: searchValue,
      onChange: setSearchValue,
      autoFocus,
      placeholder: canUseParentInputSearchValue ? parentInputValue : void 0,
      onFocus: () => setSearchSource("combobox")
    }
  );
}

export { DropdownSearchComponent, SearchInput };
