"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { useElements } from '../useElements.mjs';

function useDataSources(query) {
  const { ...rest } = useElements("data-sources", query);
  return {
    dataSources: rest.items,
    ...rest
  };
}

export { useDataSources };
