"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useAppDataSchemaInstance(selector) {
  const {
    item: appDataSchemaInstance,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.AppDataSchemaInstance,
    selector,
    (integrationApp) => selector ? integrationApp.appDataSchemaInstance(selector) : void 0
  );
  async function setup() {
    await accessor?.setup();
    await refresh();
  }
  return {
    appDataSchemaInstance,
    accessor,
    refresh,
    setup,
    ...rest
  };
}

export { useAppDataSchemaInstance };
