"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext } from 'react';

const FIELDS_TO_SHOW = 5;
function useDataBuilder() {
  return useContext(DataBuilderContext);
}
const DataBuilderContext = createContext({
  drilldownStack: [],
  isAdminMode: false,
  isReadOnly: false,
  showNullValueFields: false,
  hideReadOnlyFields: false,
  frozenFieldsLocators: [],
  errorFieldsLocators: [],
  editableVariablesSchemaLocators: [],
  collapsedDataBuilder: false,
  initialVisibleFieldCount: FIELDS_TO_SHOW
});
DataBuilderContext.displayName = "DataBuilderContext";
const DataBuilderProvider = ({
  topLevelField,
  isAdminMode = false,
  children,
  errorFieldsLocators = [],
  frozenFieldsLocators = [],
  editableVariablesSchemaLocators = [],
  onAddVariable,
  readOnly = false,
  showNullValueFields = false,
  hideReadOnlyFields = false,
  collapsedDataBuilder = false,
  initialVisibleFieldCount = FIELDS_TO_SHOW
}) => {
  return /* @__PURE__ */ jsx(
    DataBuilderContext.Provider,
    {
      value: {
        topLevelField,
        isAdminMode,
        isReadOnly: readOnly,
        errorFieldsLocators,
        frozenFieldsLocators,
        showNullValueFields,
        hideReadOnlyFields: !!hideReadOnlyFields,
        editableVariablesSchemaLocators,
        onAddVariable,
        initialVisibleFieldCount,
        collapsedDataBuilder
      },
      children
    }
  );
};

export { DataBuilderProvider, useDataBuilder as default };
