"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import clsx from 'clsx';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';
import { visuallyHidden } from '../../styled-system/patterns/visually-hidden.mjs';

const itemNestedIndicatorRecipe = sva({
  className: "item-nested-indicator",
  slots: ["wrapper", "children-counter", "counter-hidden-title"],
  base: {
    wrapper: {
      display: "flex",
      alignItems: "center"
    },
    "children-counter": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      w: "4",
      h: "4",
      color: "slate.11",
      textStyle: "sm"
    },
    "counter-hidden-title": {}
  }
});
const pluralRules = new Intl.PluralRules("en");
const pluralForms = {
  one: "child",
  other: "children"
};
function ItemNestingIndicator({
  // TODO: add tests for counter
  count,
  children
}) {
  const styles = itemNestedIndicatorRecipe();
  return /* @__PURE__ */ jsxs("div", { className: styles.wrapper, children: [
    !!count && /* @__PURE__ */ jsxs("span", { className: styles["children-counter"], children: [
      count,
      /* @__PURE__ */ jsx(
        "span",
        {
          className: clsx(styles["counter-hidden-title"], visuallyHidden()),
          children: pluralForms[pluralRules.select(1)]
        }
      )
    ] }),
    children
  ] });
}

export { ItemNestingIndicator };
