"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

const DROPDOWN_NAVIGATOR_ID = "dropdown-navigator";
const DROPDOWN_SEARCH_INPUT_ID = "dropdown-search-input";

export { DROPDOWN_NAVIGATOR_ID, DROPDOWN_SEARCH_INPUT_ID };
