"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { useElements } from '../useElements.mjs';

function useDataLinkTables(query) {
  const { ...rest } = useElements("data-link-tables", query);
  return {
    dataLinkTables: rest.items,
    ...rest
  };
}

export { useDataLinkTables };
