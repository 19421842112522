"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { toHeaderCase } from 'js-convert-case';
import { isValidLocator, locatorToSteps, getSchemaByLocator, getNameComponentsForLocator, locatorToField, stepsToLocator, getIconUriForLocator, DataLocatorStepObjectProperty, DataLocatorStepArrayItem } from '@integration-app/sdk';
import { Fragment as Fragment$1 } from 'react';
import { SegmentSeparator } from '../../Tag/SegmentSeparator.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { doesSchemaMatchValueType } from '../value-spec.mjs';
import { ObjectFieldsOptionFactory } from './object-fields.mjs';
import { ConstantOptionFactory } from './constant.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';

function getOptionsForSchema({
  schema,
  locator,
  valueSpec,
  showIcons = true,
  skipNameComponentsPrefix = [],
  ignorePrefix = false,
  nameComponentsByLocator = null,
  schemaByLocator = null,
  exampleVariables = null,
  expandObject = true,
  editableSchemaLocators = []
}) {
  if (!isValidLocator(locator)) {
    return [];
  }
  const locatorSteps = locatorToSteps(locator);
  const options = [];
  const optionsSchema = schemaByLocator ?? getSchemaByLocator(schema, locator);
  if (!optionsSchema) {
    return [];
  }
  let nameComponents = nameComponentsByLocator ?? getNameComponentsForLocator(schema, locator);
  nameComponents = ignorePrefix ? nameComponents : nameComponents.filter(
    // If skipNameComponentsPrefix is set, filter out its items from name components
    (component, idx) => !(skipNameComponentsPrefix?.[idx] === component)
  );
  const isSchemaEditable = editableSchemaLocators.some(
    (l) => locatorToField(stepsToLocator(locatorSteps)).startsWith(locatorToField(l))
  );
  const label = getLabelNodeFromNameComponents(nameComponents);
  const textLabel = nameComponents?.length > 0 ? nameComponents.join(" ") : optionsSchema.title;
  const searchLabel = nameComponents.join(" ");
  const iconUri = showIcons ? getIconUriForLocator(schema, locator) : null;
  const iconType = !iconUri ? SvgIconType.ListOption : void 0;
  if (optionsSchema.type == "object") {
    const isInvisibleObject = optionsSchema.title === "";
    if (expandObject || isInvisibleObject) {
      const hideWholeObject = !optionsSchema.title && locator?.length === 0;
      if (doesSchemaMatchValueType(optionsSchema, valueSpec) && !hideWholeObject) {
        options.push({
          label: label ?? textLabel,
          searchLabel,
          value: {
            $var: stepsToLocator(locatorToSteps(locator))
          },
          /* FIXME: strictNullCheck temporary fix */
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          iconUri,
          iconType
        });
      }
      for (const key of Object.keys(optionsSchema.properties ?? {})) {
        const propertySchema = optionsSchema.properties[key];
        if (!propertySchema) {
          continue;
        }
        const propertyLocator = [
          ...locatorSteps,
          new DataLocatorStepObjectProperty(key)
        ];
        const newNameComponents = [...nameComponents];
        const newNameComponent = propertySchema.title ?? toHeaderCase(key);
        if (newNameComponent) {
          newNameComponents.push(newNameComponent);
        }
        options.push(
          ...getOptionsForSchema({
            schema,
            locator: propertyLocator,
            valueSpec,
            showIcons,
            skipNameComponentsPrefix,
            ignorePrefix,
            nameComponentsByLocator: newNameComponents,
            schemaByLocator: propertySchema,
            exampleVariables,
            expandObject: false,
            editableSchemaLocators
          })
        );
      }
      if (isSchemaEditable) {
        options.push({
          label: `Add${textLabel ? ` "${textLabel}"` : ""} Property`,
          searchLabel: "",
          iconType: SvgIconType.Add,
          addVariable: `${stepsToLocator(locatorSteps)}`
        });
      }
    } else {
      if (doesSchemaMatchValueType(optionsSchema, valueSpec, true) || isSchemaEditable) {
        const propCount = Object.keys(optionsSchema.properties ?? {}).length;
        options.push({
          label,
          searchLabel,
          childrenCount: propCount > 0 ? propCount : void 0,
          /* FIXME: strictNullCheck temporary fix */
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          iconUri,
          iconType,
          drillDownOptionFactory: new ObjectFieldsOptionFactory({
            name: textLabel,
            schema,
            locator,
            exampleVariables,
            isSection: true,
            editableSchemaLocators
          }),
          childCategory: DropdownCategoryType.VARIABLE
        });
      }
    }
  } else if (optionsSchema.type == "array") {
    if (doesSchemaMatchValueType(optionsSchema, valueSpec, true) || doesSchemaMatchValueType(optionsSchema.items, valueSpec, true)) {
      const listOptions = [];
      if (doesSchemaMatchValueType(optionsSchema, valueSpec)) {
        listOptions.push({
          label,
          searchLabel,
          value: {
            $var: stepsToLocator(locatorToSteps(locator))
          },
          iconType,
          iconUri: iconUri || void 0
        });
      }
      if (optionsSchema.items && doesSchemaMatchValueType(optionsSchema.items, valueSpec, true)) {
        const firstArrayItemLocator = [
          ...locatorSteps,
          new DataLocatorStepArrayItem(0)
        ];
        listOptions.push(
          ...getOptionsForSchema({
            schema,
            locator: firstArrayItemLocator,
            valueSpec,
            showIcons,
            skipNameComponentsPrefix,
            ignorePrefix,
            nameComponentsByLocator: ["Item #1"],
            schemaByLocator: optionsSchema.items,
            exampleVariables,
            expandObject,
            editableSchemaLocators
          })
        );
      }
      options.push({
        label,
        searchLabel,
        /* FIXME: strictNullCheck temporary fix */
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        iconUri,
        iconType,
        drillDownOptionFactory: new ConstantOptionFactory(listOptions)
      });
    }
  } else {
    if (doesSchemaMatchValueType(optionsSchema, valueSpec)) {
      options.push({
        label,
        searchLabel,
        value: {
          $var: stepsToLocator(locatorToSteps(locator))
        },
        /* FIXME: strictNullCheck temporary fix */
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        iconUri,
        iconType
      });
    }
  }
  return options;
}
function getLabelNodeFromNameComponents(nameComponents) {
  if (!nameComponents?.length) {
    return null;
  }
  if (nameComponents.length === 1) {
    return nameComponents[0];
  }
  return /* @__PURE__ */ jsx(Fragment, { children: nameComponents.map((name, idx) => /* @__PURE__ */ jsxs(Fragment$1, { children: [
    idx ? /* @__PURE__ */ jsx(SegmentSeparator, {}) : null,
    name
  ] }, idx)) });
}

export { getOptionsForSchema };
