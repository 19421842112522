"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

function debounce(fn, ms) {
  let timer = null;
  const debouncedFunc = (args) => new Promise((resolve) => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      resolve(fn(args));
    }, ms);
  });
  const teardown = () => timer && clearTimeout(timer);
  return [debouncedFunc, teardown];
}

export { debounce as default };
