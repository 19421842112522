"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { useState, useLayoutEffect } from 'react';
import useResizeObserver from './useResizeObserver.mjs';

const useIsTextTruncated = (ref) => {
  const [isTruncated, setIsTruncated] = useState(false);
  useResizeObserver(ref, () => {
    isTruncatedCb(ref.current, setIsTruncated);
  });
  useLayoutEffect(() => {
    isTruncatedCb(ref.current, setIsTruncated);
  }, [ref]);
  return isTruncated;
};
function isTruncatedCb(el, callback) {
  if (!el) return;
  const isTruncated = isEllipsisActive(el);
  callback?.(isTruncated);
}
function isEllipsisActive(el) {
  return el.scrollWidth !== el.offsetWidth ? el.scrollWidth > el.offsetWidth : checkRanges(el);
}
function checkRanges(el) {
  const range = new Range();
  range.selectNodeContents(el);
  const range_rect = range.getBoundingClientRect();
  const el_rect = el.getBoundingClientRect();
  if (range_rect.right > el_rect.right) {
    return true;
  }
  const textOverflowPropName = "text-overflow";
  const elTextOverflow = el.style.getPropertyValue(textOverflowPropName);
  const elTextOverflowPriority = el.style.getPropertyPriority(textOverflowPropName);
  el.style.setProperty(textOverflowPropName, "ellipsis", "important");
  const rects_ellipsis = range.getClientRects();
  el.style.setProperty(textOverflowPropName, "clip", "important");
  const rects_clipped = range.getClientRects();
  el.style.setProperty(
    textOverflowPropName,
    elTextOverflow,
    elTextOverflowPriority
  );
  return rects_clipped.length !== rects_ellipsis.length;
}

export { useIsTextTruncated };
