"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { filterOptionsByLabel } from './utils.mjs';

class EnumOptionFactory {
  constructor(params) {
    this.params = params;
  }
  async getOptions({
    input
  }) {
    return this.getOptionsSync({ input });
  }
  getOptionsSync({ input }) {
    const options = [];
    if (this.params.schema.enum && Array.isArray(this.params.schema.enum)) {
      this.params.schema.enum.map((enumValue) => {
        if (typeof enumValue == "object" && enumValue.value) {
          options.push({
            label: enumValue.label ?? enumValue.value,
            value: enumValue.value,
            iconType: SvgIconType.ListOption
          });
        } else {
          options.push({
            label: enumValue,
            value: enumValue,
            iconType: SvgIconType.ListOption
          });
        }
      });
    }
    const filteredOptions = filterOptionsByLabel(options, input);
    if (filteredOptions.length > 0) {
      return [
        {
          label: "Possible Values",
          iconUri: this.params.iconUri,
          isSection: true
        },
        ...filteredOptions
      ];
    } else {
      return [];
    }
  }
}

export { EnumOptionFactory };
