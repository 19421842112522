"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import useEventStopPropagation from '../../../../hooks/useEventStopPropagation.mjs';
import { SvgIcon } from '../../../SvgIcon/index.mjs';
import { SvgIconType } from '../../../SvgIcon/svg-icon-type.mjs';
import { DEFAULT_FIELD_KEY } from '../../data-field.mjs';
import '../../../DataBuilderUI/index.mjs';
import { FieldControlPanel } from '../../../DataBuilderUI/FieldControlPanel/index.mjs';

function ControlPanelForNonObjectRootField({
  addFieldLabel = "Add Field",
  onAdd
}) {
  const handleOnClick = useEventStopPropagation(() => onAdd(DEFAULT_FIELD_KEY));
  return /* @__PURE__ */ jsx(FieldControlPanel.ButtonGroup, { children: /* @__PURE__ */ jsxs(FieldControlPanel.Button, { onClick: handleOnClick, children: [
    /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.Add }),
    addFieldLabel
  ] }) });
}

export { ControlPanelForNonObjectRootField };
