"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Root, Fallback, Image } from './styled/avatar.mjs';

const Avatar = forwardRef((props, ref) => {
  const { name, src, ...rootProps } = props;
  return /* @__PURE__ */ jsxs(Root, { ref, ...rootProps, children: [
    /* @__PURE__ */ jsx(Fallback, { children: getInitials(name) || /* @__PURE__ */ jsx(UserIcon, {}) }),
    /* @__PURE__ */ jsx(Image, { src, alt: name })
  ] });
});
Avatar.displayName = "Avatar";
const UserIcon = () => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    strokeWidth: "2",
    children: [
      /* @__PURE__ */ jsx("title", { children: "User Icon" }),
      /* @__PURE__ */ jsx("path", { d: "M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" }),
      /* @__PURE__ */ jsx("circle", { cx: "12", cy: "7", r: "4" })
    ]
  }
);
const getInitials = (name = "") => name.split(" ").map((part) => part[0]).splice(0, 2).join("").toUpperCase();

export { Avatar };
