"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { isFormula } from '@integration-app/sdk';
import { toHeaderCase } from 'js-convert-case';
import { SvgIcon } from '../../SvgIcon/index.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { getFieldSchemaOptions } from '../data-field.mjs';
import useDataBuilderField from '../field-context.mjs';
import { useDataBuilderFieldCanUseVarFromVariables } from '../hooks.mjs';
import { Root, Trigger, Positioner, Content, Item, ItemGroup, ItemGroupLabel } from '../../../ui-kit/styled/menu.mjs';
import { Icon } from '../../../ui-kit/styled/icon.mjs';
import '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import { sva } from '../../../styled-system/css/sva.mjs';

const recipe = sva({
  className: "type_selector",
  slots: ["trigger", "content", "item", "groupContainer", "groupTitle"],
  base: {
    trigger: {
      rounded: "sm",
      px: "1",
      display: "flex",
      alignItems: "center",
      color: "neutral.11",
      gap: "0.5",
      _hover: {
        bg: "neutral.2"
      },
      _focusVisible: {
        bg: "neutral.2"
      }
    },
    content: {
      bg: "surface.default",
      borderWidth: "thin",
      borderColor: "neutral.5",
      px: "3",
      py: "2.5",
      rounded: "sm",
      minW: "48",
      w: "auto",
      shadow: "none",
      flexDirection: "row",
      alignItems: "start",
      justifyContent: "start",
      gap: "5"
    },
    item: {
      display: "flex",
      flexDir: "row",
      gap: "2",
      px: "2.5",
      py: "2",
      rounded: "sm",
      margin: "0",
      fontWeight: "normal",
      w: "full",
      h: "auto",
      '&[data-selected="true"]': {
        fontWeight: "bold"
      },
      "&[data-highlighted]": {
        bg: "touchable.hover"
      },
      _hover: {
        bg: "touchable.hover"
      }
    },
    groupContainer: {
      display: "flex",
      flexDir: "column",
      gap: "0",
      w: "full",
      minW: "48"
    },
    groupTitle: {
      fontSize: "xs",
      color: "neutral.9",
      fontWeight: "bold",
      px: "2.5",
      py: "2",
      m: "0"
    }
  }
});
function FieldTypeSelector({
  onChange,
  onSchemaChange,
  ...rest
}) {
  const classes = recipe();
  const { field, localSchema } = useDataBuilderField();
  const showUseVarFromVariables = useDataBuilderFieldCanUseVarFromVariables();
  const schemaOptions = getFieldSchemaOptions(field).map(
    (schemaOption) => ({
      value: schemaOption.type,
      label: toHeaderCase(schemaOption.type),
      iconType: SvgIconType[`SchemaType_${schemaOption.type}`],
      active: localSchema?.type === schemaOption.type
    })
  );
  const showTypeSelector = schemaOptions.length > 1;
  if (!showTypeSelector && !showUseVarFromVariables) {
    return null;
  }
  return /* @__PURE__ */ jsxs(Root, { lazyMount: true, unmountOnExit: true, ...rest, children: [
    ":",
    /* @__PURE__ */ jsxs(
      Trigger,
      {
        className: classes.trigger,
        "aria-label": "Change schema type",
        children: [
          localSchema?.type ?? "select",
          /* @__PURE__ */ jsx(Icon, { w: "3", h: "4", pt: "1px", children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.ChevronDown }) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Positioner, { children: /* @__PURE__ */ jsxs(Content, { className: classes.content, children: [
      showTypeSelector && /* @__PURE__ */ jsx(TypeSelector, { options: schemaOptions, onChange: onSchemaChange }),
      showUseVarFromVariables && /* @__PURE__ */ jsx(FieldDropdown, { onChange })
    ] }) })
  ] });
}
function TypeSelector({
  options,
  onChange
}) {
  if (options.length <= 1) {
    return null;
  }
  return /* @__PURE__ */ jsx(GroupContainer, { title: "Field Type", children: options.map(({ value, iconType, label, active }) => /* @__PURE__ */ jsx(
    MenuItem,
    {
      onClick: () => onChange(value),
      icon: iconType,
      "data-selected": active,
      children: label
    },
    String(value)
  )) });
}
function FieldDropdown({ onChange }) {
  const { localSchema } = useDataBuilderField();
  switch (localSchema?.type) {
    case "object":
      return /* @__PURE__ */ jsx(FieldObjectDropdown, { onChange });
    case "array":
      return /* @__PURE__ */ jsx(FieldArrayDropdown, { onChange });
    default:
      return null;
  }
}
function FieldObjectDropdown({ onChange }) {
  const { field, setAutoOpen } = useDataBuilderField();
  function handleUseObjectFromVariables() {
    setAutoOpen(true);
  }
  function handleSetIndividualFields() {
    onChange(void 0);
  }
  const valueIsIndividualFields = !isFormula(field.value);
  return /* @__PURE__ */ jsx(GroupContainer, { title: "Field Value", children: valueIsIndividualFields ? /* @__PURE__ */ jsx(MenuItem, { onClick: handleUseObjectFromVariables, children: "Use object from variables" }) : /* @__PURE__ */ jsx(MenuItem, { onClick: handleSetIndividualFields, children: "Set individual fields" }) });
}
function FieldArrayDropdown({ onChange }) {
  const { field, setAutoOpen } = useDataBuilderField();
  function handleUseListFromVariables() {
    setAutoOpen(true);
  }
  function handleSetIndividualItems() {
    onChange(void 0);
  }
  const valueIsIndividualFields = !isFormula(field.value);
  return /* @__PURE__ */ jsx(GroupContainer, { title: "Field Value", children: valueIsIndividualFields ? /* @__PURE__ */ jsx(MenuItem, { onClick: handleUseListFromVariables, children: "Use list from variables" }) : /* @__PURE__ */ jsx(MenuItem, { onClick: handleSetIndividualItems, children: "Set individual items" }) });
}
function MenuItem({
  icon,
  children,
  ...props
}) {
  const classes = recipe();
  return /* @__PURE__ */ jsxs(Item, { ...props, className: classes.item, value: String(children), children: [
    icon && /* @__PURE__ */ jsx(Icon, { w: "5", h: "5", children: /* @__PURE__ */ jsx(SvgIcon, { type: icon }) }),
    children
  ] });
}
function GroupContainer({
  children,
  title
}) {
  const classes = recipe();
  return /* @__PURE__ */ jsxs(ItemGroup, { className: classes.groupContainer, children: [
    /* @__PURE__ */ jsx(ItemGroupLabel, { className: classes.groupTitle, children: title }),
    children
  ] });
}

export { FieldTypeSelector };
