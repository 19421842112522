"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { makeDataField } from '../index.mjs';
import DataBuilderFormField from '../Field.mjs';

const ConcatFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Combine strings"
    ] }) });
  }
);
ConcatFormula.displayName = "ConcatFormula";
function ConcatFormulaDropdown({
  onChange,
  formula,
  variablesSchema,
  optionFactories
}) {
  function handleOnChange(value2) {
    onChange?.({
      $concat: value2
    });
  }
  const isShortcutFormulaSyntax = Array.isArray(formula.args);
  const value = isShortcutFormulaSyntax ? { values: formula.args } : formula.args ?? {};
  const field = makeDataField({
    schema: {
      type: "object",
      required: ["values"],
      properties: {
        values: {
          type: "array",
          items: {
            type: "string"
          }
        },
        delimiter: {
          type: "string"
        }
      }
    },
    value,
    variablesSchema,
    optionFactories
  });
  return /* @__PURE__ */ jsx(DataBuilderFormField, { field, onChange: handleOnChange });
}

export { ConcatFormulaDropdown, ConcatFormula as default };
