"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { getSchemaFromValue, isFormula, getFormula, DataBuilderFormulaType, getSchemaByLocator, makeObjectPropertyLocator, setValueAtLocator, buildData, schemaIsScalar } from '@integration-app/sdk';
import { toHeaderCase } from 'js-convert-case';

function getFieldSchemaOptions(field) {
  if (field?.schema?.type) {
    if (Array.isArray(field.schema.type)) {
      return field.schema.type.map((type) => ({ type }));
    } else {
      return [field.schema];
    }
  } else if (Array.isArray(field?.schema?.anyOf)) {
    const options = [];
    for (const anyOfSchema of field?.schema?.anyOf ?? []) {
      options.push(...getFieldSchemaOptions({ ...field, schema: anyOfSchema }));
    }
    return options;
  } else {
    return [
      {
        type: "string"
      },
      {
        type: "number"
      },
      {
        type: "boolean"
      },
      {
        type: "object"
      },
      {
        type: "array"
      }
    ];
  }
}
const DEFAULT_FALLBACK_SCHEMA = { type: "string" };
function getFieldSchemaFromValue(field) {
  if (!field?.value) {
    return void 0;
  }
  const options = getFieldSchemaOptions(field);
  const schemaFromValue2 = getSchemaFromValue(field.value, field.variablesSchema);
  const option = options.find((o) => o.type === schemaFromValue2?.type);
  return option ?? options?.[0] ?? schemaFromValue2;
}
function getDefaultFieldSchemaOption(field) {
  if (field?.value) {
    return getFieldSchemaFromValue(field);
  }
  const options = getFieldSchemaOptions(field);
  return options?.[0] ?? DEFAULT_FALLBACK_SCHEMA;
}
function schemaFromValue(value, variablesSchema) {
  const schemaType = schemaTypeFromValue(value, variablesSchema);
  return schemaType ? { type: schemaType } : {};
}
function schemaTypeFromValue(value, variablesSchema) {
  if (isFormula(value)) {
    const formula = getFormula(value);
    if (formula.type === DataBuilderFormulaType.VAR) {
      const schema = getSchemaByLocator(
        variablesSchema,
        formula.locator
      );
      return schema?.type ?? "string";
    } else if (formula.type == DataBuilderFormulaType.PLAIN) {
      return schemaTypeFromValue(formula.value);
    } else {
      return "string";
    }
  } else {
    switch (typeof value) {
      case "object":
        if (Array.isArray(value)) {
          return "array";
        } else {
          return "object";
        }
      case "boolean":
        return "boolean";
      case "bigint":
      case "number":
        return "number";
      default:
        return "string";
    }
  }
}
function extractFieldSubFields(field) {
  let subfields = [];
  const schema = getDefaultFieldSchemaOption(field);
  if (!schema?.type) {
    subfields = getSubfieldsFromValue(field);
  } else if (schema?.type === "object") {
    const subfieldsFromObjectSchema = getSubfieldsFromObjectSchema(field);
    const subfieldsFromValue = getSubfieldsFromValue(field);
    subfields = mergeSubfieldsFromObjectSchemaAndValues(
      subfieldsFromObjectSchema,
      subfieldsFromValue
    );
    subfields = subfields.length > 0 ? [
      ...subfields.slice(0, -1),
      { ...subfields.slice(-1)[0], isLastChild: true }
    ] : subfields;
  } else if (schema?.type === "array") {
    if (Array.isArray(field.value)) {
      subfields = getSubfieldsFromValue(field);
    }
  }
  return subfields;
}
function mergeSubfieldsFromObjectSchemaAndValues(subfieldsFromObjectSchema, subfieldsFromValue) {
  function getSubfieldKey(field) {
    return field.key ?? "root";
  }
  if (subfieldsFromValue.length === 0) {
    return subfieldsFromObjectSchema;
  }
  const subfieldMap = /* @__PURE__ */ new Map();
  subfieldsFromObjectSchema.forEach((field) => {
    subfieldMap.set(getSubfieldKey(field), field);
  });
  subfieldsFromValue.forEach((field) => {
    const fieldKey = getSubfieldKey(field);
    const primaryField = subfieldMap.get(fieldKey);
    if (primaryField) {
      subfieldMap.set(fieldKey, { ...primaryField, value: field.value });
    } else {
      subfieldMap.set(fieldKey, field);
    }
  });
  return Object.values(Object.fromEntries(subfieldMap.entries()));
}
function makeSubField(field, overrides) {
  const childFieldData = {
    key: overrides?.key,
    schema: overrides?.schema,
    level: field.level + 1,
    // default behavior
    optionFactories: field.optionFactories,
    variablesSchema: field.variablesSchema,
    exampleVariables: field.exampleVariables,
    iconUri: field.iconUri,
    dynamicSchema: field.dynamicSchema,
    dynamicSchemaVariables: field.dynamicSchemaVariables
  };
  if (overrides) {
    Object.entries(overrides).forEach(([key, value]) => {
      childFieldData[key] = value;
    });
  }
  childFieldData.subFields = extractFieldSubFields(childFieldData);
  return childFieldData;
}
function getSubfieldsFromObjectSchema(field) {
  const fields = [];
  const schema = getDefaultFieldSchemaOption(field);
  if (schema?.properties) {
    Object.entries(schema.properties).forEach(([key, value]) => {
      const isRequired = schema.required && schema.required.includes(key);
      fields.push(
        makeSubField(field, {
          key,
          valueLocator: makeObjectPropertyLocator(field.valueLocator, key),
          isRequired,
          isDeletable: !isRequired,
          isInSchema: !value?.isImplied,
          hasSiblings: countObjectFieldProperties(field) > 1,
          schema: value,
          value: field.value ? field.value[key] : void 0,
          level: getLevelForNestedFields(field)
        })
      );
    });
  }
  return fields;
}
function fieldLabel(field) {
  return field.schema?.title ?? toHeaderCase(field.key ?? "");
}
function getSubfieldsFromValue(field) {
  const fields = [];
  if (Array.isArray(field.value)) {
    return field.value.map((item, idx) => {
      const dynamicSchemaVariables = structuredClone(
        field.dynamicSchemaVariables
      );
      const valueLocatorForDynamicSchema = field?.valueLocator?.replace(/\[\d+\]/g, "") ?? "";
      setValueAtLocator(
        dynamicSchemaVariables,
        valueLocatorForDynamicSchema,
        item
      );
      const currentEvaluatedSchema = buildData(
        field.dynamicSchema,
        buildData(dynamicSchemaVariables)
      );
      const valueLocator = `${field.valueLocator}[${idx}]`;
      const schema = getSchemaByLocator(currentEvaluatedSchema, valueLocator);
      return makeSubField(field, {
        key: `item_${idx + 1}`,
        valueLocator,
        isInSchema: !schema?.isImplied,
        hasSiblings: field.value.length > 0,
        isLastChild: idx === field.value.length,
        schema,
        value: item,
        isDeletable: true,
        dynamicSchemaVariables
      });
    });
  } else if (typeof field.value == "object" && field.value !== null && !isFormula(field.value)) {
    Object.entries(field.value).forEach(([key, value]) => {
      if (value === void 0) {
        return;
      }
      if (!field.schema || !field.schema.properties || !(key in field.schema.properties)) {
        fields.push(
          /* FIXME: strictNullCheck temporary fix */
          // @ts-expect-error TS(2345): Argument of type 'IDataBuilderField' is not assign... Remove this comment to see the full error message
          makeSubField(field, {
            key,
            valueLocator: `${field.valueLocator}.${key}`,
            isRequired: false,
            hasSiblings: countObjectFieldProperties(field) > 1,
            isInSchema: false,
            isDeletable: true,
            schema: void 0,
            value,
            level: getLevelForNestedFields(field)
          })
        );
      }
    });
  }
  return fields;
}
function isFieldVisible(field) {
  if (field.schema?.type === "object" || field.schema?.type === "array") {
    if ((field.isInSchema || field.level === 1) && !field.schema?.title && !field.hasSiblings && !field.key) {
      return false;
    }
  }
  return true;
}
function countObjectFieldProperties(field) {
  const fieldKeys = [];
  if (typeof field.value === "object" && field.value !== null) {
    fieldKeys.push(...Object.keys(field.value));
  }
  if (field.schema && field.schema.properties) {
    fieldKeys.push(...Object.keys(field.schema.properties ?? {}));
  }
  return new Set(fieldKeys).size;
}
function getLevelForNestedFields(field) {
  return isFieldVisible(field) ? field.level + 1 : field.level;
}
function isObjectAllowedAdditionalProperties(field) {
  if (field.schema?.type !== "object") {
    return true;
  }
  if (Object.keys(field.schema.properties ?? {}).length === 0) {
    return true;
  }
  return !!field.schema?.additionalProperties;
}
function getFieldValueProps(field) {
  const isEmpty = field.value === null || field.value === void 0;
  const isRequired = field.isRequired;
  return {
    isEmpty,
    isRequired
  };
}
function schemaIsScalarArray(schema) {
  return schema?.type === "array" && schema.items && schemaIsScalar(schema.items);
}
function isRootField(field) {
  return field.level === 1 && !isFieldVisible(field);
}
const DEFAULT_FIELD_KEY = "";

export { DEFAULT_FIELD_KEY, extractFieldSubFields, fieldLabel, getDefaultFieldSchemaOption, getFieldSchemaFromValue, getFieldSchemaOptions, getFieldValueProps, getLevelForNestedFields, isFieldVisible, isObjectAllowedAdditionalProperties, isRootField, makeSubField, schemaFromValue, schemaIsScalarArray };
