/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "/* Dropdown Popper */\n.Popper-module_popper__HPG3S {\n  z-index: 600;\n  will-change: transform, width, max-height;\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n  display: flex;\n  flex-direction: column;\n}\n.Popper-module_popper__HPG3S[data-popper-reference-hidden='true'] {\n  visibility: hidden;\n  pointer-events: none;\n}\n.Popper-module_popper__HPG3S[data-popper-placement^='top'] {\n  justify-content: flex-end;\n\n  /*\n  When Dropdown is placed on the top, it has additional offset\n  Because we shift Dropdown to the top by 24px to keep SchemaSelector visible\n  */\n  margin-bottom: 20px;\n  margin-left: -9px;\n}\n.Popper-module_popper__HPG3S[data-popper-placement^='bottom'] {\n  justify-content: flex-start;\n}\n";
var classes = {"popper":"Popper-module_popper__HPG3S"};
styleInject(css_248z);

export { classes as default };
