"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { DataBuilderFormulaType, schemaWithTitle } from '@integration-app/sdk';
import useDataBuilderField from '../field-context.mjs';
import { Truncate } from '../../Truncate/index.mjs';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { forwardRef } from 'react';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { ActionBar } from '../../DataBuilderUI/index.mjs';
import useEventStopPropagation from '../../../hooks/useEventStopPropagation.mjs';
import DataBuilderCondition from '../Condition/index.mjs';
import DataBuilderFormField from '../Field.mjs';
import { makeSubField } from '../data-field.mjs';
import classes from './styles.module.css.mjs';
import '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import { sva } from '../../../styled-system/css/sva.mjs';
import { Button } from '../../../ui-kit/button.mjs';

const addCaseRecipe = sva({
  className: "add-case",
  slots: ["button"],
  base: {
    button: {
      w: "fit-content",
      h: "fit-content",
      rounded: "xl",
      color: "slate.11",
      px: "2",
      py: "1",
      my: "2"
    }
  }
});
const CaseFormula = forwardRef(
  (_props, ref) => {
    return /* @__PURE__ */ jsx(ComboBoxTag, { ref, icon: SvgIconType.Array, variant: "lookup", children: /* @__PURE__ */ jsx(Truncate, { children: "Conditional value" }) });
  }
);
CaseFormula.displayName = "CaseFormula";
function CaseFormulaDropdown({
  formula,
  onChange
}) {
  const { field } = useDataBuilderField();
  const styles = addCaseRecipe();
  if (formula?.type !== DataBuilderFormulaType.CASE) {
    return /* @__PURE__ */ jsx("span", { children: "Unexpected value for Case drilldown. Something went wrong." });
  }
  const defaultValue = formula.value?.default;
  const cases = formula.value?.cases ?? [];
  function handleChange(value) {
    onChange?.({
      $case: value
    });
  }
  function handleCaseChange(index, value) {
    const newConditions = [...cases];
    newConditions[index] = value;
    handleChange({
      cases: newConditions,
      default: defaultValue
    });
  }
  function handleNewCase() {
    handleChange({
      cases: [...cases, { filter: [], value: null }],
      default: defaultValue
    });
  }
  function handleDeleteCase(idx) {
    const newConditions = [...cases];
    newConditions.splice(idx, 1);
    handleChange({
      cases: newConditions,
      default: defaultValue
    });
  }
  function handleDefaultValueChange(value) {
    handleChange({
      cases,
      default: value
    });
  }
  function makeCaseField(item, index) {
    return makeSubField(field, {
      valueLocator: `${field.valueLocator}.$case.cases[${index}]`,
      schema: {
        type: "object",
        properties: {
          value: schemaWithTitle(field.schema ?? {}, `Case ${index + 1}`)
        }
      },
      optionFactories: field.optionFactories,
      exampleVariables: field.exampleVariables,
      variablesSchema: field.variablesSchema,
      level: 1,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      key: null,
      value: item,
      isInSchema: true
    });
  }
  const defaultCaseField = makeSubField(field, {
    valueLocator: `${field.valueLocator}.$case.default`,
    schema: schemaWithTitle(field.schema ?? {}, `Default`),
    optionFactories: field.optionFactories,
    exampleVariables: field.exampleVariables,
    variablesSchema: field.variablesSchema,
    level: 1,
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    key: null,
    value: defaultValue,
    isInSchema: true
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    cases.map((item, index) => /* @__PURE__ */ jsx(
      CaseItem,
      {
        field: makeCaseField(item, index),
        item,
        onChange: (value) => handleCaseChange(index, value),
        onDelete: () => handleDeleteCase(index)
      },
      index
    )),
    /* @__PURE__ */ jsx(
      Button,
      {
        onClick: handleNewCase,
        variant: "outline",
        colorPalette: "gray",
        size: "sm",
        className: styles.button,
        children: "+ Add Case"
      }
    ),
    /* @__PURE__ */ jsx(DataBuilderFormField, { field: defaultCaseField, onChange: handleDefaultValueChange })
  ] });
}
function CaseItem({
  field,
  item,
  onChange,
  onDelete
}) {
  function handleValueChange(value) {
    return onChange({
      ...item ?? {},
      value
    });
  }
  const filterField = makeSubField(field, {
    valueLocator: `${field.valueLocator}.filter`,
    key: "filter",
    value: item?.filter,
    schema: { type: "boolean" }
  });
  const valueField = makeSubField(field, {
    valueLocator: `${field.valueLocator}.value`,
    value: item?.value,
    isInSchema: true,
    schema: field.schema?.properties?.value,
    level: 1
  });
  function handleFilterChange(value) {
    return onChange({
      ...item ?? {},
      filter: value
    });
  }
  return /* @__PURE__ */ jsxs("div", { className: classes.caseContainer, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.field, children: [
      /* @__PURE__ */ jsx(DataBuilderFormField, { field: valueField, onChange: handleValueChange }),
      /* @__PURE__ */ jsx("div", { className: classes.deleteButton, children: /* @__PURE__ */ jsx(ActionBar.DeleteButton, { onClick: useEventStopPropagation(onDelete) }) })
    ] }),
    /* @__PURE__ */ jsx("div", { className: "ml-4", children: /* @__PURE__ */ jsx(
      DataBuilderCondition,
      {
        field: filterField,
        onChange: handleFilterChange
      }
    ) })
  ] });
}

export { CaseFormulaDropdown, CaseFormula as default };
