"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { DataBuilderFormulaType } from '@integration-app/sdk';
import { ValueType } from '../../ComboBox/value-spec.mjs';
import CaseFormula, { CaseFormulaDropdown } from './case.mjs';
import ConcatFormula, { ConcatFormulaDropdown } from './concat.mjs';
import CopyFormula, { CopyFormulaDropdown } from './copy.mjs';
import DomainFromEmailFormula, { DomainFromEmailFormulaDropdown } from './domainFromEmail.mjs';
import ExtractDateFormula, { ExtractDateFormulaDropdown } from './extractDate.mjs';
import ExtractTimeFormula, { ExtractTimeFormulaDropdown } from './extractTime.mjs';
import FindAppRecordIdFormula, { FindAppRecordIdFormulaDropdown } from './findAppRecordId.mjs';
import FindExternalRecordIdFormula, { FindExternalRecordIdFormulaDropdown } from './findExternalRecordId.mjs';
import FirstNameFormula, { FirstNameFormulaDropdown } from './firstName.mjs';
import FirstNotEmptyFormula, { FirstNotEmptyDropdown } from './firstNotEmpty.mjs';
import IterateFormula, { IteratedFormulaDropdown } from './iterate.mjs';
import JsonataFormulaTag, { JsonataFormulaDropdown } from './jsonata.mjs';
import LastNameFormula, { LastNameFormulaDropdown } from './lastName.mjs';
import LookupFormula from './lookup.mjs';
import MapFormula, { MapFormulaDropdown } from './map.mjs';
import MergeObjectsFormula, { MergeObjectsDropdown } from './mergeObjects.mjs';
import NoUiFormula from './noUi.mjs';
import RecordFormula from './record.mjs';
import TplFormula from './tpl.mjs';
import VarFormula from './var.mjs';

function getFormulas() {
  return {
    [DataBuilderFormulaType.VAR]: {
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: VarFormula
    },
    [DataBuilderFormulaType.TPL]: {
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: TplFormula
    },
    [DataBuilderFormulaType.RECORD]: {
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: RecordFormula
    },
    [DataBuilderFormulaType.FIRST_NAME]: {
      name: "Extract First Name",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type '(spec: IValueSpec) => boolean | undefined' i... Remove this comment to see the full error message
      appliesToSpec: appliesToString,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: FirstNameFormula,
      DropdownComponent: FirstNameFormulaDropdown
    },
    [DataBuilderFormulaType.LAST_NAME]: {
      name: "Extract Last Name",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type '(spec: IValueSpec) => boolean | undefined' i... Remove this comment to see the full error message
      appliesToSpec: appliesToString,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: LastNameFormula,
      DropdownComponent: LastNameFormulaDropdown
    },
    [DataBuilderFormulaType.ITERATE]: {
      name: "Iterate over a list",
      appliesToSpec: appliesToArray,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: IterateFormula,
      DropdownComponent: IteratedFormulaDropdown
    },
    [DataBuilderFormulaType.CONCAT]: {
      name: "Combine strings",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type '(spec: IValueSpec) => boolean | undefined' i... Remove this comment to see the full error message
      appliesToSpec: appliesToString,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: ConcatFormula,
      DropdownComponent: ConcatFormulaDropdown
    },
    [DataBuilderFormulaType.LOOKUP]: {
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: LookupFormula
      // Drilldown
    },
    [DataBuilderFormulaType.MAP]: {
      name: "Mapped Value",
      appliesToSpec: appliesToAny,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: MapFormula,
      DropdownComponent: MapFormulaDropdown
    },
    [DataBuilderFormulaType.CASE]: {
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: CaseFormula,
      name: "Conditional Value",
      appliesToSpec: appliesToAny,
      DropdownComponent: CaseFormulaDropdown
      // Drilldown
    },
    [DataBuilderFormulaType.PLAIN]: {
      component: NoUiFormula
    },
    [DataBuilderFormulaType.EXTRACT_DATE]: {
      name: "Extract Date",
      appliesToSpec: appliesToDate,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: ExtractDateFormula,
      DropdownComponent: ExtractDateFormulaDropdown
    },
    [DataBuilderFormulaType.EXTRACT_TIME]: {
      name: "Extract Time",
      appliesToSpec: appliesToTime,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: ExtractTimeFormula,
      DropdownComponent: ExtractTimeFormulaDropdown
    },
    [DataBuilderFormulaType.COPY]: {
      name: "Copy Value from Another Field",
      appliesToSpec: appliesToAny,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: CopyFormula,
      DropdownComponent: CopyFormulaDropdown
    },
    [DataBuilderFormulaType.FIND_APP_RECORD_ID]: {
      name: "Find App Record ID",
      appliesToSpec: appliesToAny,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Data... Remove this comment to see the full error message
      component: FindAppRecordIdFormula,
      DropdownComponent: FindAppRecordIdFormulaDropdown
    },
    [DataBuilderFormulaType.FIND_EXTERNAL_RECORD_ID]: {
      name: "Find External Record ID",
      appliesToSpec: appliesToAny,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: FindExternalRecordIdFormula,
      DropdownComponent: FindExternalRecordIdFormulaDropdown
    },
    [DataBuilderFormulaType.EVAL]: {
      component: NoUiFormula
    },
    [DataBuilderFormulaType.AND]: {
      component: NoUiFormula
    },
    [DataBuilderFormulaType.OR]: {
      component: NoUiFormula
    },
    [DataBuilderFormulaType.MERGE_OBJECTS]: {
      name: "Merge Objects",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: MergeObjectsFormula,
      appliesToSpec: appliesToObject,
      DropdownComponent: MergeObjectsDropdown
    },
    [DataBuilderFormulaType.FIRST_NOT_EMPTY]: {
      name: "First Not Empty",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: FirstNotEmptyFormula,
      appliesToSpec: appliesToAny,
      DropdownComponent: FirstNotEmptyDropdown
    },
    [DataBuilderFormulaType.DOMAIN_FROM_EMAIL]: {
      name: "Domain From Email",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<any>... Remove this comment to see the full error message
      component: DomainFromEmailFormula,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type '(spec: IValueSpec) => boolean | undefined' i... Remove this comment to see the full error message
      appliesToSpec: appliesToString,
      DropdownComponent: DomainFromEmailFormulaDropdown
    },
    [DataBuilderFormulaType.FORMULA]: {
      name: "Formula",
      component: NoUiFormula
    },
    [DataBuilderFormulaType.JSONATA]: {
      name: "Jsonata Expression",
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<IFormulaProps<Json... Remove this comment to see the full error message
      component: JsonataFormulaTag,
      appliesToSpec: appliesToAny,
      DropdownComponent: JsonataFormulaDropdown
    }
  };
}
function appliesToAny() {
  return true;
}
function appliesToString(spec) {
  return spec.type === ValueType.STRING && spec.allowCustom;
}
function appliesToArray(spec) {
  return spec.type === ValueType.ARRAY;
}
function appliesToDate(spec) {
  return spec.type === ValueType.DATE;
}
function appliesToTime(spec) {
  return spec.type === ValueType.TIME;
}
function appliesToObject(spec) {
  return spec.type === ValueType.OBJECT;
}

export { getFormulas };
