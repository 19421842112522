"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useMemo, useContext } from 'react';

const IntegrationAppConnectionContext = createContext({
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  connectionId: null
});
IntegrationAppConnectionContext.displayName = "IntegrationAppConnectionContext";
const IntegrationAppConnectionProvider = ({
  connectionId,
  children
}) => {
  const value = useMemo(() => ({ connectionId }), [connectionId]);
  return /* @__PURE__ */ jsx(IntegrationAppConnectionContext.Provider, { value, children });
};
function useIntegrationAppConnection() {
  return useContext(IntegrationAppConnectionContext);
}

export { IntegrationAppConnectionProvider, useIntegrationAppConnection };
