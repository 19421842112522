"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { DropdownStackContextProvider } from '../context/combobox-dropdown-stack.mjs';
import { SearchInput } from './SearchInput.mjs';
import { ActiveCategoryContent } from './ActiveCategoryContent.mjs';
import { DrillUpButton } from './DrillUpButton.mjs';
import { DropdownPopoverProvider, DropdownAnchor, DropdownPopoverContent } from '../DropdownPopover/index.mjs';
import { NavigationContextProvider } from './NavigationContext.mjs';

function Dropdown({
  topDropdownPanelComponent,
  autoFocusOnSearch,
  onOptionSelect,
  children
}) {
  const { availableCategories } = useComboBoxDropdownContext();
  if (availableCategories === null) {
    return children;
  }
  if (availableCategories?.length < 1) {
    return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsxs(DropdownPopoverProvider, { children: [
      /* @__PURE__ */ jsx(DropdownAnchor, { children }),
      /* @__PURE__ */ jsx(DropdownPopoverContent, { children: /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {}),
        /* @__PURE__ */ jsx(DropdownUI.LoadingItem, {})
      ] }) })
    ] }) });
  }
  return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsxs(DropdownPopoverProvider, { children: [
    /* @__PURE__ */ jsx(DropdownAnchor, { children }),
    /* @__PURE__ */ jsx(NavigationContextProvider, { children: /* @__PURE__ */ jsxs(DropdownPopoverContent, { children: [
      /* @__PURE__ */ jsx(DrillUpButton, {}),
      topDropdownPanelComponent && /* @__PURE__ */ jsx(Fragment, { children: topDropdownPanelComponent }),
      /* @__PURE__ */ jsx(SearchInput, { autoFocus: autoFocusOnSearch }),
      /* @__PURE__ */ jsx(ActiveCategoryContent, { onOptionSelect })
    ] }) })
  ] }) });
}

export { Dropdown };
