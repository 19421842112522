"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';

const dropdownRecipe = sva({
  className: "dropdown",
  slots: [
    "popover-container",
    "content",
    "panel",
    "search-input",
    "search-divider",
    "list",
    "item",
    "no-options-heading",
    "error-heading",
    "error-heading-icon",
    "loading-item-container",
    "loading-item-icon",
    "loading-item-title",
    "items-list-header",
    "item-title"
  ],
  base: {
    "popover-container": {
      outline: "none",
      display: "flex",
      overflow: "hidden",
      background: "white",
      boxShadow: "xl",
      rounded: "lg",
      borderColor: "gray.2",
      borderWidth: "0.5",
      padding: "0",
      maxW: "none",
      maxH: "var(--available-height, 100%)",
      h: "full",
      w: "fit-content"
    },
    content: {
      display: "flex",
      flexDir: "column",
      p: "1.5",
      w: "full",
      minW: "56",
      maxW: "min(65em, 90vw)",
      maxH: "var(--available-height, 100%)"
    },
    panel: {
      display: "flex",
      flexDir: "column",
      overflow: "hidden",
      pb: "1.5",
      minH: "8",
      _last: {
        pb: "0"
      }
    },
    "search-input": {
      w: "full",
      rounded: "none",
      border: "none",
      px: "2",
      py: "1.5",
      textStyle: "md",
      fontWeight: "medium",
      _placeholder: {}
    },
    "search-divider": {
      pt: "0.5",
      pb: "1.5"
    },
    list: {
      overflowY: "auto",
      position: "relative",
      flexGrow: "1",
      minH: "8",
      maxW: "md"
    },
    item: {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      cursor: "pointer",
      minH: "6",
      _hover: {
        bg: "slate.3"
      },
      _current: {
        bg: "slate.3"
      },
      rounded: "md"
    },
    "no-options-heading": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      minH: "6",
      color: "slate.10",
      fontWeight: "medium",
      textStyle: "sm"
    },
    "error-heading": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1",
      minH: "6",
      fontWeight: "medium",
      textStyle: "sm"
    },
    "error-heading-icon": {
      color: "tomato.10",
      h: "7",
      w: "7"
    },
    "loading-item-container": {
      display: "flex",
      flexDir: "row",
      gap: "1.5",
      alignItems: "center",
      px: "2",
      py: "1"
    },
    "loading-item-icon": {
      h: "6",
      w: "6",
      rounded: "md"
    },
    "loading-item-title": {
      h: "5",
      w: "3/5",
      rounded: "md"
    },
    "items-list-header": {
      display: "flex",
      p: "2",
      color: "slate.11",
      textTransform: "uppercase",
      fontSize: "xs",
      fontWeight: "medium",
      letterSpacing: "wide",
      position: "sticky",
      top: "0",
      zIndex: "docked",
      minH: "auto",
      pointerEvents: "none",
      background: "white"
    },
    "item-title": {
      display: "flex",
      flexGrow: "1",
      flexDir: "row",
      alignItems: "baseline",
      gap: "1",
      maxW: "full",
      overflowWrap: "break-word",
      textStyle: "sm",
      fontWeight: "medium"
    }
  }
});

export { dropdownRecipe };
