"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { useComboboxEditor, Editor } from './Editor.mjs';

const StandAloneEditor = forwardRef(({ value, onChange, ...props }, ref) => {
  const { editor } = useComboboxEditor({
    // Do now allow manipulating the content (add new, remove existing, etc.)
    // but existing <Tags /> can be edited, because they are separate components
    editable: false,
    value,
    onChange
  });
  return /* @__PURE__ */ jsx(Editor, { editor, ref, ...props });
});
StandAloneEditor.displayName = "StandAloneEditor";

export { StandAloneEditor };
