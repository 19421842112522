"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { ark } from '@ark-ui/react/factory';
import { forwardRef } from 'react';
import { styled } from '../../styled-system/jsx/factory.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/patterns/visually-hidden.mjs';
import '../../styled-system/css/conditions.mjs';
import { skeleton } from '../../styled-system/recipes/skeleton.mjs';

const StyledSkeleton = styled(ark.div, skeleton);
const Skeleton = forwardRef(
  (props, ref) => {
    const { isLoaded, ...otherProps } = props;
    if (isLoaded) {
      return /* @__PURE__ */ jsx(styled.div, { animation: "fade-in", ref, ...otherProps });
    }
    return /* @__PURE__ */ jsx(StyledSkeleton, { ref, ...otherProps });
  }
);
Skeleton.displayName = "Skeleton";

export { Skeleton };
