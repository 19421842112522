/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".InlineEditable-module_wrapper__yfCEq,\n.InlineEditable-module_input__5qjuK {\n\n  color: currentColor\n}\n\n.InlineEditable-module_wrapper__yfCEq {\n\n  position: relative;\n\n  display: inline-block;\n\n  cursor: default\n}\n\n.InlineEditable-module_span__Mc5je {\n\n  visibility: visible;\n\n  display: block;\n\n  --placeholder-text: 'Enter some text'\n}\n\n.InlineEditable-module_span__Mc5je:empty::after {\n\n  --tw-text-opacity: 1;\n\n  color: rgb(156 163 175 / var(--tw-text-opacity));\n  content: var(--placeholder-text)\n}\n\n.InlineEditable-module_wrapperRequired__1EmUH .InlineEditable-module_span__Mc5je:empty::after {\n\n  --tw-text-opacity: 1;\n\n  color: rgb(153 27 27 / var(--tw-text-opacity))\n}\n\n.InlineEditable-module_input__5qjuK {\n\n  position: absolute;\n\n  top: 0px;\n\n  left: 0px;\n\n  right: 0px;\n\n  bottom: 0px;\n\n  width: 100%;\n\n  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;\n\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n\n  transition-duration: 150ms;\n\n  margin: 0px;\n\n  padding: 0px;\n  border-top-color: transparent !important;\n  opacity: 0\n}\n\n.InlineEditable-module_input__5qjuK:hover,\n.InlineEditable-module_input__5qjuK:focus {\n\n  opacity: 1\n}\n\n.InlineEditable-module_input__5qjuK:focus {\n\n  outline: 2px solid transparent;\n\n  outline-offset: 2px\n}\n\n.InlineEditable-module_input__5qjuK:focus + .InlineEditable-module_span__Mc5je {\n\n  visibility: hidden\n}\n\n.InlineEditable-module_wrapper__yfCEq:hover .InlineEditable-module_span__Mc5je {\n\n  visibility: hidden\n}\n\n.InlineEditable-module_wrapper__yfCEq:hover .InlineEditable-module_input__5qjuK {\n\n  visibility: visible\n}\n";
var classes = {"wrapper":"InlineEditable-module_wrapper__yfCEq","input":"InlineEditable-module_input__5qjuK","span":"InlineEditable-module_span__Mc5je","wrapperRequired":"InlineEditable-module_wrapperRequired__1EmUH"};
styleInject(css_248z);

export { classes as default };
