"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.2
 */
'use client';

import { getNameComponentsForLocator, getFullNameForLocator, getNameForLocator, getIconUriForLocator } from '@integration-app/sdk';
import { DropdownCategoryType } from '../types.mjs';
import { renderCategories } from '../Dropdown/categoryContentMapping.mjs';
import { getOptionsForSchema } from './generate-schema-options.mjs';

class VariableOptionFactory {
  constructor(parameters) {
    this.parameters = parameters;
  }
  async getOptions({
    input,
    spec
  }) {
    return this.getOptionsSync({ input, spec });
  }
  getOptionsSync({ input, spec }) {
    const locator = this.parameters.locator ?? "$";
    const showFullPath = !!input;
    const options = getOptionsForSchema({
      schema: this.parameters.schema,
      locator: this.parameters.locator ?? [],
      valueSpec: spec,
      showIcons: !this.parameters.isSection,
      skipNameComponentsPrefix: getNameComponentsForLocator(
        this.parameters.schema,
        locator
      ),
      ignorePrefix: !showFullPath,
      nameComponentsByLocator: this.parameters.isSection ? getNameComponentsForLocator(this.parameters.schema, locator) : [],
      exampleVariables: this.parameters.exampleVariables,
      editableSchemaLocators: this.parameters.editableSchemaLocators
    });
    const filteredOptions = options.filter(
      (o) => !input || (o.searchLabel ?? o.label?.toString())?.toLowerCase()?.includes(input.toLowerCase())
    );
    if (filteredOptions.length > 0) {
      const result = filteredOptions;
      if (this.parameters.isSection) {
        result.unshift({
          label: showFullPath ? getFullNameForLocator(this.parameters.schema, locator) : getNameForLocator(this.parameters.schema, locator),
          isSection: true,
          iconUri: getIconUriForLocator(this.parameters.schema, locator)
        });
      }
      result.unshift({
        label: renderCategories[DropdownCategoryType.VARIABLE].title,
        isSection: true
      });
      return result;
    } else {
      return [];
    }
  }
}

export { VariableOptionFactory };
